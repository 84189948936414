export default {
  bind(el: HTMLElement, binding: any) {
    // Apply sanitization here if needed
    el.innerHTML = binding.value;
  },
  update(el: HTMLElement, binding: any) {
    // Again, sanitize if necessary
    el.innerHTML = binding.value;
  },
};

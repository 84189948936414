export default {
  bind(el: HTMLElement, binding: any) {
    const url = binding.value;
    if (url && (url.startsWith('http://') || url.startsWith('https://'))) {
      el.setAttribute('href', url);
    } else {
      el.removeAttribute('href');
    }
  },
  update(el: HTMLElement, binding: any) {
    const url = binding.value;
    if (url && (url.startsWith('http://') || url.startsWith('https://'))) {
      el.setAttribute('href', url);
    } else {
      el.removeAttribute('href');
    }
  },
};

import { globalStore as global } from '@/store/global';

function loadScript(id) {
  const script = document.createElement('script');
  script.src = `https://td.yieldify.com/yieldify/code.js?w_uuid=${id}&k=1&loca=${encodeURIComponent(window.location.href)}"`;
  script.async = true;
  document.getElementsByTagName('head')[0].appendChild(script);
}

const srcHandlers = [
  {
    shouldHandle(src) {
      return src.indexOf('https://td.yieldify.com/yieldify/') >= 0;
    },
    handle(src) {
      const urlparser = document.createElement('a');
      urlparser.href = src;

      const { origin } = urlparser;
      const endIndex = src.indexOf(origin) + origin.length;
      const route = src.slice(endIndex);

      return `//${global.yieldify_proxy_host}${route}`;
    },
  },
];

function manipulateSrcValue(value) {
  // eslint-disable-next-line
  for (const handler of srcHandlers) {
    if (handler.shouldHandle(value)) {
      return handler.handle(value);
    }
  }

  return value;
}

function monkeyPatchDocumentCreateElement() {
  const createElementBackup = document.createElement;
  document.createElement = (...args) => {
    const element = createElementBackup.bind(document)(...args);

    // If this is not a script tag, bypass
    const wantedElement = args[0].toLowerCase();
    if (wantedElement !== 'script' && wantedElement !== 'iframe') {
      return element;
    }

    const originalSetAttribute = element.setAttribute.bind(element);

    Object.defineProperties(element, {
      src: {
        get() {
          return element.getAttribute('src');
        },
        set(value) {
          originalSetAttribute('src', manipulateSrcValue(value));
        },
      },
    });

    return element;
  };
}

let installed = false;

export default {
  install(Vue, { id }) {
    if (!installed) {
      monkeyPatchDocumentCreateElement();
      loadScript(id);
      installed = true;
    }
  },
};
